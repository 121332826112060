import React from "react"
import classnames from "classnames"

/* Import Global Components */
import InterviewAnswer from "~components/interviewAnswer/interviewAnswer"
import InterviewQuestion from "~components/interviewQuestion/interviewQuestion"
import Image from "~components/image/image"

/* Import Local Assets */
import LovingSuitsSrc from "../../assets/1-loving-suits.jpg"
import OrdenamientoSilvestreSrc from "../../assets/2-ordenamiento-silvestre.jpg"
import AnticonquistaCafeSrc from "../../assets/3-anticonquista-cafe.jpg"
import ThisIsWeirdWithoutYouSrc from "../../assets/4-this-is-weird-without-you.jpg"

import styles from "./content.module.css"

const EnglishContent = ({ active }) => (
  <div className={classnames(styles.content, { [styles.active]: active })}>
    <p>
      Interview by Maria Camila Montalvo Senior with SHE Collective,{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/colectivosehablaespanol/"
      >
        Se habla español
      </a>
      , formed by Noelia Lecue Francia, María Alejandra Sáenz García, Andrea
      Valencia Aranda, and Natalia Viera Salgado.
    </p>
    <p>
      The curatorial collective <i>Se habla español</i>, comprised of Noelia
      Lecue Francia, María Alejandra Sáenz Garcia, Andrea Valencia Aranda and
      Natalia Viera Salgado, invites artists, designers, writers, photographers
      and curators around the world to submit a work, poem, image or short video
      (you can use your cell phone camera!) to share a message of SOLIDARITY in
      these uncertain times. The purpose of the images, which we will be sharing
      with our networks, will be to reimagine the future after the pandemic.
      Send your message along with a short paragraph and the image credit to
      colectivosehablaespanol@gmail.com. Let's spread SOLIDARITY.
    </p>
    <InterviewQuestion interviewer="Maria Camila Montalvo Senior (MCMS)">
      In the present moment, I have seen many things in my daily life that I
      have not seen before. It is as if from one moment to the next, the
      dimension of time has been amplified and I am able to sit and contemplate
      everything around me in slow motion. For example, I discovered that my
      African neighbors who live in community, practice rituals in their
      backyard at night. I sit and listen to their music and I think they dance;
      I can barely see their shadows or silhouettes because it is so dark. Then
      in the morning, I see trunks nailed to the ground and tied together with
      red ribbon. A mandala of stones surrounds them. I look for African music
      on Spotify and listen to it all day. It is as if through observation, I
      have become part of the ritual and of that community, which opened up
      another world to me, another dimension. I see the same thing in{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B_QTZZXB4Fl/"
      >
        Caro Dalfó's
      </a>{" "}
      piece for{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B-hyWvyhUKs/"
      >
        SOLIDARITY
      </a>
      . She says, "It is short, simple and mundane . . . My neighbor, whom I do
      not know, practices his saxophone and it makes my day. A small moment of
      happiness.” In both these cases, I find gestures of solidarity. It is a
      solidarity generated by an internal cohesion that comes from the
      observation of what exists at this moment and with those particular
      sensations. Both cases bring a degree of fantasy to the enchantment. But I
      also believe that the gesture of my contemplation or that of Caro is key
      within this solidarity. Everyone is there for me, everyone is being
      supportive; those who observe and those who listen. In this regard, I
      would like you to define the concept of solidarity more precisely in
      relation to the work you have received and to this moment in time. For
      you, what is solidarity?
    </InterviewQuestion>
    <InterviewAnswer subject="Se habla español (SHE)">
      Solidarity is a concept that goes beyond this particular public health
      emergency. Since the beginning of <i>Se habla español</i>, the collective
      has had a spirit of collaboration and mutual support that has been
      essential for the creation of our projects. Solidarity is a way of
      relating to others, understanding that we are a network and that if one
      part of the network is vulnerable, then we are all vulnerable. On the
      contrary, when we support each other and strengthen the network, we all
      become stronger.
    </InterviewAnswer>
    <InterviewAnswer>
      At this moment, solidarity is a way of creating bridges to connect us in
      different ways and on different levels. The SOLIDARITY HAS NO BORDERS
      initiative was born from a feeling of empathy and affection towards each
      other, of extending a hand in a difficult moment of social distancing to
      continue strengthening our community. Now, solidarity demands from us more
      than a gesture, it demands that we contribute and create a platform for
      those who wish to express themselves. Our proposal was also born out of a
      need to amplify messages of empathy in a time when collective fear and
      panic have spread in an alarming way.
    </InterviewAnswer>
    <InterviewAnswer>
      The poet Arundhati Roy recently said, "Historically, pandemics have forced
      humans to break with the past and imagine their world anew. This one is no
      different. It is a portal, a gateway between one world and the next." For
      us, the importance of this project is related to the power in the creation
      of that world to come.
    </InterviewAnswer>
    <Image src={LovingSuitsSrc} alt="Loving Suits" />
    <InterviewQuestion interviewer="MCMS">
      This time we are living through with COVID-19 is, in short, a time in
      which we are forced to observe ourselves and one another. But here I am
      talking about a real observation and not the observation that seeks to
      self-reference all the time. That is to say, while observing and
      listening, one forgets about oneself in order to really see the other, and
      that is when one is present. This is related to the piece{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B-_Eb7jhwbi/"
      >
        LovingSUITS
      </a>{" "}
      by Gina Goico, which speaks of the power of being present in a community,
      of our narratives and memories of love: "It exists in sensorial presence,
      in the connection generated by listening, smelling or touching the pieces.
      Today, while embroidering for the first time in two weeks, I understood:
      part of my art is to weave communities. My passion is to create spaces of
      communion for communication, reflection and human physical integration. I’
      m happy listening to others. I’m happy facilitating communication. I'm
      happy when we are healed by love." I also find this to be related to the
      previous reflection. For you, what is the relationship between solidarity
      and community? And in relation to communication, is communication a
      gesture of solidarity or of love?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B-_Eb7jhwbi/"
      >
        LovingSUITS
      </a>{" "}
      is a great example of solidarity. The idea of self-love and affection has
      been a recurring subject in Gina Goico’s work. According to her, this
      project took longer than she expected. “It's taken me a month. A month to
      make a piece I could usually make in a day. A month in which I got sick, I
      supported my students, I supported my close community of teachers, I gave,
      but to my understanding, I couldn't ‘give’ to my art. Because it hurt.
      <i>LovingSUITS</i> speaks of the power of being present in a community, of
      our narratives and memories of love. <i>LovingSUITS</i> exist in a
      sensorial presence, in the connection generated by hearing, smelling or
      touching the pieces. Today, as I embroidered for the first time in two
      weeks, I understood: part of my art is to weave communities. My passion is
      to create spaces of communion for communication, reflection and human
      physical integration. I am happy listening to others. I am happy
      facilitating communication.” <i>LovingSUITS II</i> also connects to the
      first
      <i>LovingSUITS</i>, which she understands as a developing project that
      encompasses soft weighted sculptures and multimedia documentation of
      Dominican women's narratives of family dynamics, love and self-love. In
      the future, <i>LovingSUITS</i> will collaborate with researchers and
      family therapists to create epistemic content and objects that can assist
      in therapy.
    </InterviewAnswer>
    <InterviewAnswer>
      We can also talk about{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.the8thfloor.org/past-events/performance-as-repair"
      >
        Performance as Repair (2018)
      </a>
      , a program we organized at The 8th Floor, a gallery established by the
      Rubin Foundation in New York. This event explored the more performative
      idea of reparation and rituals to bring us closer to collective or
      individual healing. The artists{" "}
      <a target="_blank" rel="noreferrer" href="http://www.ginagoico.com/">
        Gina Goico
      </a>{" "}
      and{" "}
      <a target="_blank" rel="noreferrer" href="http://ivansikic.com/">
        Iván Sikic
      </a>{" "}
      with Paul Cannon investigated the relationship between language and
      performance in their pieces, as a way of acting on social and historical
      contracts—that remain unfulfilled—in order to restore agency and encourage
      reparation.
      <i>Performance as Repair</i> presented a live performance, a video
      projection and an artists’ talk to establish a conversation about how
      language and words have a performative role in our society.
    </InterviewAnswer>
    <Image src={OrdenamientoSilvestreSrc} alt="Loving Suits" />
    <InterviewQuestion interviewer="MCMS">
      I relate to the exercise of observing and listening to silence. Over the
      last month and a half we have been on pause ("New York State on PAUSE").
      Since then I have been immersed in meditation. I had been practicing yoga
      for years but had never spent so much time sitting in silence. This time,
      physical and energetic changes happened that, without leading to
      mysticism, serve as a new space or arrangement in my body. From this
      space, I observe and listen—I consider it a space for solidarity. I relate
      this to the pieces{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B_m1hrPhanT/"
      >
        Ordenamiento Silvestre [Wild Ordering] and Ir y Retornar [Going and
        Return] by Sarabel Santos-Negrón
      </a>
      . According to the artist, this is a meditation exercise, through "a
      catalogue and a calendar of leaves, in which I keep memories of the
      passage of time, the fragility of life and the change in an organism when
      it is separated from its natural state." Santos-Negrón describes it as a
      socio-environmental and political-economic ritual. In this regard, I would
      like to know how you think this ritual is political or social? And how
      does this relate to the concept of solidarity?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      Recognizing the passage of time in the quest to preserve memory is a
      political and social act, even more so when it is related to nature.
      Sarabel Santos-Negrón invites us to pause and recognize, in an act of
      contemplation and repetition, the value of memory. Nature allows us acts
      of remembrance and recollection that help us evoke moments that trigger
      many stories from the past to the present. The works{" "}
      <i>Ordenamiento Silvestre</i> [Wild Ordering] and <i>Ir y Retornar</i>{" "}
      [Going and Return] give an account of this and help complete the stories
      of this moment of pause that we are all living. Santos-Negrón's message of
      solidarity reminds us of
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.the8thfloor.org/past-events-01/2019/11/15/screening-distancia-se-habla-espaol-in-conversation-with-ensayos"
      >
        DISTANCIA
      </a>{" "}
      (2019), a program that we presented in collaboration with the Chilean
      collective Ensayos. Shown for the first time in New York, <i>DISTANCIA</i>{" "}
      is an ethnographic web series by Ensayos, which addresses problems related
      to the political ecology in Tierra del Fuego, Chile. After the screening,
      <i>Se habla español</i> moderated a conversation between the members of
      Ensayos, Carla Macchiavello, Camila Marambio and Carolina Saquel. The
      conversation revolved around ethical bio-cultural practices and issues in
      representing the landscape. This collective research focuses on
      extinction, human geography and coastal health. Formed in the Tierra del
      Fuego archipelago, Ensayos first focused on the ecopolitical issues
      affecting the archipelago and its inhabitants—past and present, human and
      non-human.
    </InterviewAnswer>
    <InterviewAnswer>
      Both projects address the impact of these issues on nature, environmental
      policies and rising social and economic inequality. This moment of pause
      has made the strong fractures in our society even more evident, the
      enormous economic and social inequity, the precariousness of public health
      in our countries (Colombia, Mexico, Puerto Rico, Spain) and in New York
      City, and the clear crisis of neoliberal capitalism. This reality, which
      has always been there, but today is becoming more evident, has led us to
      establish a network of solidarity.
    </InterviewAnswer>
    <Image src={AnticonquistaCafeSrc} alt="Loving Suits" />
    <InterviewQuestion interviewer="MCMS">
      On the other hand, there is the work{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B_Tii4vB2yI/"
      >
        Anticonquista Café
      </a>{" "}
      by Lauren Reese, which seeks to "dismantle the notion that farm workers
      are non-professionals and non-essential.” According to the author, it
      attempts to "put an end to the current cycle of land appropriation by the
      companies and return it to the hands of the small peasant workers.” In
      this work, the political is evident. So is solidarity. Considering this,
      how does solidarity work to support political agency?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      Of course, solidarity is a political act. First of all, solidarity implies
      a mode of self-organization. Knowing that we can fight collectively for a
      common goal openly invites us to exercise our political agency. Moreover,
      solidarity helps us generate a shared vision, which makes us walk together
      towards our goals and overcome the obstacles we face.
    </InterviewAnswer>
    <InterviewAnswer>
      Lauren's project in particular seems important to us because it makes us
      think about issues such as food sovereignty and agriculture in our
      countries, self-management and collaboration in community. Her photographs
      capture the sensitivity in cooperation, the strength of what it means to
      be an agent of change.
    </InterviewAnswer>
    <InterviewQuestion interviewer="MCMS">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/watch/?v=1492537630924082"
      >
        The collaboration
      </a>{" "}
      between Maria Camila Montalvo Senior with Se habla español collective
      began on{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://radiokingston.org/es/broadcast/la-voz-con-mariel-fiori/episodes/kingston-with-covid-19-day-36-and-the-project-solidaridad-de-se-habla-espanol?fbclid=IwAR15SwhAeno8_TN5JwNahzkNkLpm149NhZC4okbIA6iCTwotFwyhOin8GqE"
      >
        the program La Voz with Mariel Fiori
      </a>{" "}
      on Radio Kingston, to which they were invited guests. La Voz is a
      Spanish-language radio program that serves a community of 140,000 Latinx
      people living in the Hudson Valley and Catskill Mountains. I find many
      commonalities between <i>La Voz</i> and <i>SHE</i>: both serve the Latinx
      community of New York and are also creating community. Both <i>La Voz</i>{" "}
      and <i>SHE</i>
      use language as a mechanism for cultural integration. <i>La Voz</i> is the
      only Spanish-language radio program here in Upstate New York and your
      collective <i>SHE</i> is an acronym for <i>Se habla español</i>. On the
      other hand, Noelia highlights a problem with the Spanish language in
      relation to its context in Spain, which is different from its context in
      the United States and Latin America. I would like you to develop the
      language issue a bit in reference to your project. Is language a political
      tool? Is language an instrument for solidarity?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      <i>Se habla español</i> was born in 2017 out of a need to make an
      underrepresented community visible. The Spanish language is not only the
      second most spoken language in the United States, but also worldwide.
      According to 2019 statistics, Mexico has the largest number of Spanish
      speakers, followed by the US, Colombia and Spain, with the US being the
      only country where Spanish is not an official language.
    </InterviewAnswer>
    <InterviewAnswer>
      Today, very few American institutions, and more specifically those in New
      York (because of our common context), are bilingual. There have been great
      advances in recent years, but the Spanish language has not yet reached all
      health, cultural or government institutions. Guadalupe Ruiz Fajardo,
      Professor of Latin American and Iberian Cultures at Columbia University,
      and collaborator in one of our programs at The 8th Floor, mentioned in an
      interview with{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.elmundo.es/andalucia/2018/09/02/5b8a7425e5fdeaa4438b45cb.html"
      >
        El Mundo
      </a>{" "}
      in late 2018 that these inequalities are still in place: "Here English is
      the language of power. In the US, Spanish is the language of the workers.”
    </InterviewAnswer>
    <InterviewAnswer>
      Indeed, language is and has been used historically as a political tool,
      not always from within the community or from the perspective of the social
      fabric, stemming instead from the political interests of those in power.
      We work within the artistic context and we consider it very relevant to
      work with this approach and in this way, because we do not only use
      language as a communication tool; we are interested in expanding its
      limits by exploring the impact and influence that migration, identity,
      human rights and memory have on it. The mission of <i>Se habla español</i>{" "}
      is to develop, through collective effort and diverse perspectives,
      projects and research that respond to contemporary reality by creating
      platforms for reflection and artistic production.
    </InterviewAnswer>
    <InterviewAnswer>
      In the same way, the acronym <i>SHE</i>, from <i>Se habla español</i>, in
      English refers to her voice, a fact that really reinforces the way we have
      always positioned ourselves when we speak and that certainly coincides
      with that of Mariel Fiori in <i>La Voz</i>, not only because of our shared
      perspective of Spanish speakers, women or migrants, but also because we
      are spreading and amplifying these voices to a community that expands
      beyond our circles.
    </InterviewAnswer>
    <InterviewAnswer>
      In the context of Spain, the Spanish language coexists regionally with
      Basque, Catalan and Galician, but since their prohibition during Franco's
      dictatorship, their use has become so politicized that in places where two
      languages coexist, if a bilingual person decides to use one over the other
      at certain times, it is linked to their political affiliations. In our
      case, we use Spanish outside the national contexts of Spain, Mexico,
      Colombia or even the complicated case of Puerto Rico and we focus
      specifically on the strength of the union and amplification of that chorus
      that encompasses the different variants and cultures.
    </InterviewAnswer>
    <InterviewQuestion interviewer="MCMS">
      You told me that the four of you met at the{" "}
      <a target="_blank" rel="noreferrer" href="https://www.macp.sva.edu/">
        Curatorial Practice program at the School of Visual Arts
      </a>
      , where you started a Spanish (or Castilian) dictionary containing
      variations of words that share meanings in each of your countries. What is
      the most suggestive variation of words in your dictionary?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      The dictionary came up very spontaneously during our daily conversations.
      We began to feel that although we all speak Spanish, we speak very
      different Spanish. One of the most memorable anecdotes is a time when we
      planned to meet and <i>comer</i>. Andrea, Noelia and Natalia planned to
      have lunch [<i>comida</i>] around 1 p.m., while María Alejandra thought
      they were going to meet around 7 p.m. After clearing up the
      “misunderstanding,” we realized that in Mexico, Spain and Puerto Rico
      mealtimes are usually called <i>desayuno</i>, <i>comida</i> and{" "}
      <i>cena</i> [breakfast, lunch and dinner], while in Colombia they say
      <i>desayuno</i>, <i>almuerzo</i> and <i>comida</i>. We also recall other
      "misunderstandings" with words like <i>ordenador</i>, <i>computadora</i>{" "}
      and <i>computador</i>
      [which all mean computer] or <i>esfero</i>, <i>pluma</i> and{" "}
      <i>bolígrafo</i> [which all mean pen]. As a result of this, we decided to
      keep a record of the different words we each used to refer to the same
      thing and thus the dictionary was created.
    </InterviewAnswer>
    <InterviewAnswer>
      This exercise, together with our interest in the immigration situation in
      the United States, led us to develop{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.the8thfloor.org/past-events/breaking-and-unveiling-the-immigration-glossary-of-severalty"
      >
        Breaking and Unveiling the Immigration Glossary of Severalty
      </a>{" "}
      (2018), a participatory workshop that we presented at The 8th Floor at the
      invitation of Sara Reisman, the Executive and Artistic Director of the
      Shelley & Donald Rubin Foundation. The program <i>Severalty</i> [a term
      commonly used to describe the separation and sole ownership of private
      property] investigated the nature of immigrant identity and the language
      surrounding immigration in the context of US contemporary culture and
      media by proposing a glossary of terms that constantly entail exclusion
      and reinforce differences between people. We examined terms such as:
      alien, foreigner, border, brain drain, Dreamer, immigrant,
      Latino-Hispanic, national, refugee and sanctuary, among others. We invited
      experts from different disciplines of art, education, activism and law
      who, together with the participants, took part in a non-hierarchical
      conversation to deconstruct these concepts and reconsider the use and
      effects of immigration terminology in this political moment.
    </InterviewAnswer>
    <InterviewQuestion interviewer="MCMS">
      I would like you to tell me what the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://mujeresmirandomujeres.com/colectivo-she-se-habla-en-espanol-female-migrations/"
      >
        Female Migrations
      </a>{" "}
      initiative is about and if you find any connection to your SOLIDARITY
      project. How do migration policies relate to messages of solidarity? Do
      these two aspects of your work correlate?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      In <i>Female Migrations</i> (2019) we presented four Latin American
      artists who approach their artistic practice from the female perspective
      of migration, a proposal that, in addition to social advocacy, is rooted
      in the ideals of empathy and solidarity developed in the current project.
      Female Migrations draws attention to the historically neglected vision and
      role of women in the current grave reality of migration, through the
      personal experiences and artistic practices of the guest artists: Karina
      Aguilera Skvirsky, Simone Couto, Amanda Gutiérrez and Esperanza Mayobre.{" "}
    </InterviewAnswer>
    <InterviewAnswer>
      Migration agency and solidarity are aspects that we have indeed been
      developing in our work since we formed the collective and are exemplified
      in a very concrete and visual way in each of the events that formed the
      <i>Female Migrations</i> series.
    </InterviewAnswer>
    <InterviewAnswer>
      In this way, Karina Aguilera Skvirsky paid tribute to the migration
      history of her Afro-Ecuadorian great-grandmother through her work{" "}
      <i>The Perilous Journey of María Rosa Palacios</i> and presented the
      connection between altruism and participatory action, linking popular
      narratives with current discourse on borders, migration and nationality.
    </InterviewAnswer>
    <InterviewAnswer>
      Amanda Gutiérrez, in a second event, presented a performative virtual
      reality documentary on soundwalks with the work{" "}
      <i>Flâneuse>La caminanta</i>. This documentary was recorded with
      female-identified participants narrating the bodily dangers and sensations
      they have experienced in what is for many a dangerous public space.
    </InterviewAnswer>
    <InterviewAnswer>
      For <i>2 x 2 inches</i>, Simone Couto invented the stories of immigrant
      women whose pictures were missing from the membership cards of the archive
      of the Spanish Benevolent Society, La Nacional, in New York around the
      1920s. Simone asked twenty-two female artists to write a personal letter
      to those faceless women in order to reconstruct their identities,
      somewhere between reality and fiction. This gesture of sensitivity by
      Simone Couto draws a strong parallel with the painful situation of the
      COVID-19 crisis in New York City, with the large number of immigrants who
      have died, who have not been claimed and who will remain anonymous.
    </InterviewAnswer>
    <InterviewAnswer>
      The topic of Esperanza Mayobre’s piece{" "}
      <i>
        Domingo familiar (It's not Sunday, but can you play dominoes with me?)
      </i>{" "}
      has also been a recurring theme in these days of uncertainty and
      confinement. When we presented this event, in August 2019, Esperanza
      Mayobre was addressing Venezuela's migration crisis by creating a space
      for unity, seeking the slightest sliver of comfort and relief to those
      around to her, establishing emotional ties and support between
      communities. These days when we think about her work and initiative it is
      even more painful and relevant to us. We wish that hugs and meetings with
      our loved ones could give us back a much-needed tranquility. This crisis,
      now international, not only attacks our health, but affects the ways in
      which our community shows affection and connection to others. So, in a
      certain way, through these projects, we rethink and position ourselves in
      solidarity with others.{" "}
    </InterviewAnswer>
    <InterviewQuestion interviewer="MCMS">
      The collective <i>Se habla español</i> is made up of four women and is
      abbreviated as <i>SHE</i>. I also see an emphasis on the role of women in
      your work, especially with the <i>Female Migrations</i> project. Right
      now, we are inundated with good-press headlines suggesting that the places
      where the COVID-19 crisis has been best managed are countries led by
      women. I would like to hear about your role as women within the global
      contemporary art context and its relationship to our current reality. What
      does it mean to be female leaders within the context of global art today?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      We're part of the most overworked and underpaid groups in the cultural
      sector, firstly because we're women, secondly because we speak Spanish and
      some of us are Latinas. It is no secret that the art world operates
      through a white, heterosexual, homogenizing male lens and gaze. It is
      quite frustrating to see how the narrative that is built around this
      profile has so much influence on, for example, the gender balance—or lack
      thereof—that exists in the collections of American institutions.
    </InterviewAnswer>
    <InterviewAnswer>
      Being a woman in this context requires understanding the positions and
      dynamics of power at the institutional level, but also not being
      intimidated by this. We recognize that there are women who are leading
      phenomenal institutions and projects and who have inspired us greatly
      throughout our practice. Some that come to mind are Suzy del Valle,
      Macarena Gómez Barris, Sofía Hernández Chong-Cuy, Hitomi Iwasaki, Marta
      Moreno Vega, Deborah Cullen, Aimé Lukin Iglesias, Carla Acevedo Yates,
      Marcela Guerrero, Arlene Dávila, Sara Demeuse, Chus Martínez, María Belén
      Sáenz, Nana Adusei-Poku, among others. Also very important to us is Sara
      Reisman, artistic director of the Shelley & Donald Rubin Foundation, who
      gave us our first curatorial opportunity as a collective at The 8th Floor.
      We believe that these women have transformed the artistic and narrative
      landscape in their capacities as cultural professionals and have
      contributed to the conversation around contemporary art.{" "}
    </InterviewAnswer>
    <InterviewAnswer>
      As female curators, we seek to use the space at <i>SHE</i> to bring
      visibility to communities that often do not have the opportunity to
      participate in dialogues. We believe that it is also important to have
      this exchange of cultures between the four of us; since we come from
      different countries and different contexts, it is fundamental to
      understand our cultures. This is also an act of solidarity.{" "}
    </InterviewAnswer>
    <Image src={ThisIsWeirdWithoutYouSrc} alt="THIS IS WEIRD WITHOUT YOU" />
    <InterviewQuestion interviewer="MCMS">
      It is a fact that COVID-19 is not just a disease in the body itself, but
      rather functions as a biopolitical agent that permeates racial, social
      class, national and mental health spheres, etc. We can see this, for
      example, in the fact that the Latinx community is the most affected by
      COVID-19 in New York, followed by the African-American community. In a
      poetic way, we also see this reflected in the works you have gathered for
      SOLIDARITY: none of the pieces speak of the disease itself. The work{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/p/B_DlaGeBzuM/"
      >
        THIS IS WEIRD WITHOUT YOU
      </a>{" "}
      (2020), by Juan Carlos Rodriguez Rivera, Marcela Pardo Ariza and Felipe
      García Jr., is defined by a poetic energy that recognizes the fact that we
      are all interconnected and that we all need the support of one another now
      more than ever. This work makes visible, in some way, an existing support
      network and at the same time invites us to reimagine a future together.
      Evidently the concept of community, which has always been present in Latin
      America, begins to occupy a central place within societies at a global
      level with COVID-19. With regard to the concept of community and
      solidarity, how do you reimagine the future?
    </InterviewQuestion>
    <InterviewAnswer subject="SHE">
      COVID-19 has indeed emphasized problems already existing in our society.
      However, we cannot forget the impact the virus has had on the lives of all
      the people who have caught it or who have suffered losses of family and
      friends. By this we mean that we are all subject to the impact and
      consequences of the pandemic, from an economic, social and emotional point
      of view.
    </InterviewAnswer>
    <InterviewAnswer>
      Every day the press, governments and other institutions provide
      information that deprives us of a future by presenting uncertain
      scenarios. Many structures that shaped our previous reality and sense of
      unity have ceased to have the practicality they once had. This makes us
      question what community is without the hugs, the dancing, the friction,
      the affection. But the truth is that human beings have found many ways to
      unite and come together throughout history. Now more than ever it’s time
      to unlearn old structures, to reflect and be creative in building new ways
      of creating community.
    </InterviewAnswer>
    <InterviewAnswer>
      Something that concerns and motivates us in this sense is a focus on
      communitarian and collaborative projects that create teaching systems
      using imagination as a starting point and foundation for the projection of
      the future we want. In reality uncertainty predominates our vision of the
      future and although we do not have a defined answer or solution, we
      believe that thinking from the perspective of solidarity helps us take a
      stand on the possibility of a less precarious and more equitable future.
      Moreover, our intention is to create a platform for artists and other
      thinkers to contribute to the formation of multiple visions of the future.
    </InterviewAnswer>
    <InterviewAnswer>
      SOLIDARITY reaffirms the work we have already been doing as a collective.
      From the start, the projects of <i>Se habla español</i> have emphasized
      the need to present artists using underrepresented social practices and
      narratives. <i>SHE</i> has facilitated a support network bringing together
      and connecting different people and disciplines. We believe that more than
      ever, communities are connecting at different levels, in other forms that
      involve physical distancing, but not social distancing—where social
      networks or virtual spaces are nothing more than new tools for
      communicating affection.
    </InterviewAnswer>
    <InterviewQuestion interviewer="MCMS">
      I remember Andrea’s words during our conversation on <i>La Voz</i> in
      which she spoke about the role of the arts: “Art is for everyone, it
      touches our deepest parts as human beings, and it is through those deepest
      parts that we connect with each other. Art, right now and always, is
      something we have to get close to in order to be together.” And today, as
      I finished writing this, I had the opportunity of being part of the
      Ceremony of the Day of the Southern Cross, conducted by Carmen Vicente, a
      medicine woman from Loja, Ecuador. Live in the Andes, people from all over
      the world joined on Zoom and came together to share, live and create
      spaces of celebration around this beautiful altar, the Chakana. Some words
      of wisdom and encouragement from this beautiful woman, who reimagines a
      future in which the art, health, spirituality and community living active
      in Indigenous and rural communities in Latin America can be used to
      confront these challenging times:
    </InterviewQuestion>
    <InterviewQuestion>
      The principle value of humans is to live in community. And so, in these
      times we are living in today, we must question our view on what we have
      been taught about the meaning of community. The word community goes
      together with the word harmony. When I went to the city, I heard different
      meanings of this word: harmony. But what is the lesson in the education we
      have received about what it means to be in harmony with the tree? Harmony
      with water, harmony with fire, harmony with earth . . . And when is this
      harmony broken? In many communities that I know, and in the countryside
      community that I come from, the value of harmony is still upheld. Harmony
      as an economy of life . . . All of us are moving at the same time. All of
      us are moving in relation to each other, while being different from one
      another. But an understanding of the harmony of each place allows a
      balance in life for all the spaces between them . . . When I bring corn to
      the altar, I am speaking about the need for community living on either
      large or small scale to serve a purpose in time . . . When we say “art” we
      are speaking about free peoples allowed to create and able to generate new
      forms. But this freedom has not yet happened and is thus not possible in
      the present, unless it is made in community collaborative work in the
      spaces we live in . . .
    </InterviewQuestion>
    <InterviewQuestion>
      How are we going to integrate the experience that we have lived in this
      time called “quarantine”? By walking once again up the steps of the
      mountains where the spirit rises. And even on the flattest of lands, there
      is a mountain of power. How will we go back to walk ancient paths, to
      continue on to the present moment? When artists create new designs, or
      when they renew known designs, that is called a “vision." When we have
      been ill and become healthy again, that health, being able to stand up
      again is already a “vision.” That vision is not just my individual freedom
      alone; no vision is realized through just one person. The vision of every
      dreamer, of every person in the community is a good deed from the
      community and for the community. So now we are working for health.
    </InterviewQuestion>
    <InterviewQuestion>
      Carmen Vicente, Ecuador, May 3rd, 2020.
    </InterviewQuestion>
  </div>
)

export default EnglishContent
